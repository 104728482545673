import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE } from './types'

import AuthService from '../services/auth.service'
import { AppDispatch } from '../store'

export const login =
  (username: string, password: string) =>
  (dispatch: AppDispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        })

        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: LOGIN_FAIL,
          payload: null
        })

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      }
    )
  }

export const logout = () => (dispatch: AppDispatch) => {
  AuthService.logout()

  dispatch({
    type: LOGOUT,
    payload: null
  })
}
