import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../helpers/checkAuth'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Copyright from '../shared/Copyright'
// import DataTable from './DataTable'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

const Home = () => {
  const classes = useStyles()

  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {/* <DataTable /> */}
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </div>
  )
}

export default Home
