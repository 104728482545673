import React, { FunctionComponent, useEffect, useState } from 'react'
import MessengerService from '../../../services/messenger.service'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { Avatar, FormControlLabel, FormGroup } from '@material-ui/core'
import { User } from '../../../store/Messenger/types'
import { CircularProgress } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'

type MessengerUsersSelectionProps = {
  selectedUsers: number[]
  onSelectedUsers: (users: number[]) => void
  search: string
}

const MessengerUsersSelection: FunctionComponent<MessengerUsersSelectionProps> =
  ({ selectedUsers, onSelectedUsers, search }) => {
    const [users, setUsers] = useState<User[]>([])
    const [usersFiltered, setUsersFiltered] = useState<User[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [checkedUsers, setCheckedUsers] = useState<number[]>([])
    const [filterType, setFilterType] = React.useState({
      rks: true,
      ph: true,
    })

    const handleFilterTypeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFilterType({
        ...filterType,
        [event.target.name]: event.target.checked,
      })
    }

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const userListResponse = await MessengerService.getUserList()
          setUsers(userListResponse.data.userList)
          setUsersFiltered(userListResponse.data.userList)
        } catch (error) {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()

          console.warn(_content)
        } finally {
          setLoading(false)
        }
      }
      fetchUsers()
    }, [])

    useEffect(() => {
      if (search !== '') {
        const filteredUsers = users.filter(
          (user) =>
            user.id === parseInt(search) ||
            user.firstname?.toLowerCase().includes(search.toLowerCase()) ||
            user.lastname?.toLowerCase().includes(search.toLowerCase()) ||
            `${user.firstname} ${user.lastname}`
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            `${user.lastname} ${user.firstname}`
              ?.toLowerCase()
              .includes(search.toLowerCase())
        )
        setUsersFiltered(filteredUsers)
      } else {
        setUsersFiltered(users)
      }

      // TODO: refactor based on API values
      setUsersFiltered((prevState) =>
        prevState.filter((user) => {
          if (filterType.ph && user.userTypeName === 'PH') {
            return user
          }
          if (filterType.rks && user.userTypeName === 'RKS') {
            return user
          }
          return null
        })
      )
    }, [search, users, filterType])

    useEffect(() => {
      setCheckedUsers(selectedUsers)
    }, [selectedUsers])

    const handleToggle = (value: number) => () => {
      const currentIndex = checkedUsers.indexOf(value)
      const newChecked = [...checkedUsers]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      setCheckedUsers(newChecked)
      onSelectedUsers(newChecked)
    }

    const renderUserItem = (
      userId: number,
      firstname: string | null,
      lastname: string | null,
      isAsm: boolean,
      iconMimeType: string | null,
      iconContent: string | null
    ) => {
      const labelId = `checkbox-list-label-${userId}`

      return (
        <ListItem
          key={userId}
          button
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
          onClick={handleToggle(userId)}
        >
          <ListItemIcon style={{ minWidth: '30px' }}>
            <Checkbox
              edge="start"
              checked={checkedUsers.indexOf(userId) !== -1}
              tabIndex={-1}
              disableRipple
              color="primary"
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemAvatar>
            <Avatar
              style={{
                border: isAsm ? '1px solid #d70000' : '1px solid #e0e4e7',
              }}
              src={`data:${iconMimeType};base64,${iconContent}`}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                {firstname} {lastname}
              </span>
            }
          />
        </ListItem>
      )
    }

    return (
      <>
        <FormGroup row style={{ paddingLeft: '16px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType.rks}
                onChange={handleFilterTypeChange}
                name="rks"
                color="primary"
              />
            }
            label="RKS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType.ph}
                onChange={handleFilterTypeChange}
                name="ph"
                color="primary"
              />
            }
            label="PH"
          />
        </FormGroup>
        <List
          style={{
            height: 'calc(100% - 200px - 42px)', // 42px - filters height
            overflowY: 'auto',
          }}
        >
          {loading && <CircularProgress />}
          {!loading &&
            usersFiltered.map((user) => {
              return renderUserItem(
                user.id,
                user.firstname,
                user.lastname,
                user.userTypeName === 'RKS',
                user.imageMimeType,
                user.imageContent
              )
            })}
        </List>
      </>
    )
  }

export default MessengerUsersSelection
