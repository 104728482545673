import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import MessengerService from '../../../services/messenger.service'
import List from '@material-ui/core/List'
import ForumIcon from '@material-ui/icons/Forum'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { Avatar } from '@material-ui/core'
import { Region, User, UserState } from '../../../store/Messenger/types'
import { CircularProgress } from '@material-ui/core'

type MessengerUsersProps = {
  channelId: number
  onUserSelect: (userId: number) => void
  search: string
  regionView: boolean
}

const MessengerUsers: FunctionComponent<MessengerUsersProps> = ({
  channelId,
  onUserSelect,
  search,
  regionView,
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [usersFiltered, setUsersFiltered] = useState<User[]>([])
  const [userListState, setUserListState] = useState<UserState[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchUserListState = useCallback(async () => {
    try {
      const userListStateResponse = await MessengerService.getUserListState(
        channelId
      )
      setUserListState(userListStateResponse.data.userListState)
    } catch (error) {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString()

      console.warn(_content)
    }
  }, [channelId])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userListResponse = await MessengerService.getUserList()
        setUsers(userListResponse.data.userList)
        setUsersFiltered(userListResponse.data.userList)

        userListResponse.data.userList.forEach((user) => {
          setRegions((prevRegions) => {
            const foundRegion = prevRegions.find(
              (region) => region.regionId === user.regionId
            )
            if (!foundRegion) {
              return [
                ...prevRegions,
                { regionId: user.regionId, regionName: user.regionName },
              ]
            }
            return [...prevRegions]
          })
        })

        // sort by regionName
        setRegions((prevRegions) =>
          prevRegions.sort((a, b) =>
            (a.regionName ? a.regionName : '') >
            (b.regionName ? b.regionName : '')
              ? 1
              : (b.regionName ? b.regionName : '') >
                (a.regionName ? a.regionName : '')
              ? -1
              : 0
          )
        )

        await fetchUserListState()
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    setSelectedUser(null)
    fetchUsers()
  }, [fetchUserListState])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchUserListState()
    }, 3000)
    return () => clearInterval(interval)
  }, [fetchUserListState])

  useEffect(() => {
    if (search !== '') {
      const filteredUsers = users.filter(
        (user) =>
          user.id === parseInt(search) ||
          user.firstname?.toLowerCase().includes(search.toLowerCase()) ||
          user.lastname?.toLowerCase().includes(search.toLowerCase()) ||
          `${user.firstname} ${user.lastname}`
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          `${user.lastname} ${user.firstname}`
            ?.toLowerCase()
            .includes(search.toLowerCase())
      )
      setUsersFiltered(filteredUsers)
    } else {
      setUsersFiltered(users)
    }
  }, [search, users])

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    const foundUser = users.find((user) => user.id === index)
    setSelectedUser(foundUser || null)
    if (foundUser) {
      onUserSelect(foundUser.id)
    }
  }

  const renderUserItem = (
    userId: number,
    firstname: string | null,
    lastname: string | null,
    newMessages: boolean,
    isAsm: boolean,
    lastMessage: string | null,
    iconMimeType: string | null,
    iconContent: string | null
  ) => {
    return (
      <ListItem
        button
        selected={selectedUser?.id === userId}
        onClick={(event) => handleListItemClick(event, userId)}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              border: isAsm ? '1px solid #d70000' : '1px solid #e0e4e7',
            }}
            src={`data:${iconMimeType};base64,${iconContent}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <span style={{ fontWeight: newMessages ? 'bold' : 'normal' }}>
              {firstname} {lastname}
            </span>
          }
          secondary={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: newMessages ? '#3aaa35' : 'inherit',
                height: '20px',
              }}
            >
              {lastMessage && (
                <>
                  <ForumIcon style={{ fontSize: 20, paddingRight: '5px' }} />
                  {lastMessage.replace('T', ' ')}
                </>
              )}
            </div>
          }
        />
      </ListItem>
    )
  }

  return (
    <List
      style={{
        height: 'calc(100% - 200px)',
        overflowY: 'auto',
      }}
    >
      {loading && <CircularProgress />}
      {!loading &&
        !regionView &&
        userListState.map((userState) => {
          const user = usersFiltered.find((u) => userState.userId === u.id)
          let newMessages = false
          let lastMessage: string | null = null

          newMessages = userState.unreadMessages > 0
          lastMessage = userState.lastMessage

          if (user) {
            return renderUserItem(
              user.id,
              user.firstname,
              user.lastname,
              newMessages,
              user.userTypeName === 'RKS',
              lastMessage,
              user.imageMimeType,
              user.imageContent
            )
          }
          return null
        })}
      {!loading &&
        regionView &&
        regions.map((region) => (
          <>
            {region.regionId && (
              <div
                style={{
                  width: 'calc(100% - 32px)',
                  borderBottom: '1px solid #ccc',
                  lineHeight: '0.1em',
                  margin: '20px 16px 10px',
                  fontSize: '16px',
                }}
              >
                <span
                  style={{
                    background: '#fff',
                    paddingRight: '10px',
                  }}
                >
                  {region.regionName}
                </span>
              </div>
            )}
            <div>
              {usersFiltered.map((user) => {
                if (user.regionId === region.regionId) {
                  const findState = userListState.find(
                    (userState) => userState.userId === user.id
                  )
                  let newMessages = false
                  let lastMessage: string | null = null

                  if (findState) {
                    newMessages = findState.unreadMessages > 0
                    lastMessage = findState.lastMessage
                  }

                  return renderUserItem(
                    user.id,
                    user.firstname,
                    user.lastname,
                    newMessages,
                    user.userTypeName === 'RKS',
                    lastMessage,
                    user.imageMimeType,
                    user.imageContent
                  )
                }
                return null
              })}
            </div>
          </>
        ))}
    </List>
  )
}

export default MessengerUsers
