import React, { FunctionComponent, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { login } from '../../actions/auth'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import Copyright from '../shared/Copyright'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

type LoginProps = {
  history: string[]
}

const Login: FunctionComponent<LoginProps> = ({ history }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [usernameError, setUsernameError] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<boolean>(false)

  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const { message } = useSelector((state: RootStateOrAny) => state.message)

  const dispatch = useDispatch()

  const onChangeUsername = (e: { target: { value: string } }) => {
    const username = e.target.value
    setUsername(username)
    setUsernameError(false)
  }

  const onChangePassword = (e: { target: { value: string } }) => {
    const password = e.target.value
    setPassword(password)
    setPasswordError(false)
  }

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (username.length === 0) {
      setUsernameError(true)
      return
    } else if (password.length === 0) {
      setPasswordError(true)
      return
    }

    setLoading(true)

    try {
      await dispatch(login(username, password))
      history.push('/home')
      window.location.reload()
    } catch (error) {
      setLoading(false)
    }
  }

  if (isLoggedIn) {
    return <Redirect to="/home" />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('pages.login.title')}
        </Typography>
        <form className={classes.form} noValidate>
          {message && (
            <Alert severity="error" style={{ marginBottom: '1.5rem' }}>
              {t('pages.login.unathorized')}
            </Alert>
          )}
          <TextField
            error={usernameError}
            helperText={usernameError ? t('pages.login.usernameError') : ''}
            variant="outlined"
            margin="none"
            value={username}
            fullWidth
            id="email"
            label={t('pages.login.email')}
            name="email"
            type="email"
            onChange={onChangeUsername}
            size="small"
            autoFocus
          />
          <TextField
            error={passwordError}
            helperText={passwordError ? t('pages.login.passwordError') : ''}
            variant="outlined"
            margin="normal"
            value={password}
            fullWidth
            name="password"
            label={t('pages.login.password')}
            type="password"
            onChange={onChangePassword}
            size="small"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t('pages.login.rememberMe')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleLogin}
            className={classes.submit}
          >
            {t('pages.login.submit')}
            {loading && (
              <CircularProgress
                size={18}
                color="secondary"
                style={{ marginLeft: '1rem' }}
              />
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t('pages.login.forgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Login
