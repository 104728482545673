import React, { FunctionComponent, useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import ForumIcon from '@material-ui/icons/Forum'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useDispatch } from 'react-redux'
import { logout } from '../../actions/auth'
import Button from '@material-ui/core/Button'
import { DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Messenger from '../Messenger/Messenger'
import MessengerService from '../../services/messenger.service'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}))

type HeaderProps = {
  open: boolean
  handleDrawerOpen: () => void
  messengerToken: string | null
}

const Header: FunctionComponent<HeaderProps> = ({
  open,
  handleDrawerOpen,
  messengerToken,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [messengerOpen, setMessengerOpen] = useState<boolean>(false)
  const [unreadMessage, setUnreadMessages] = useState<number>(0)

  const toggleMessengerDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setMessengerOpen(open)
    }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MessengerService.getUnreadMessagesCount()
        setUnreadMessages(response.data.unreadMessages)
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      }
    }

    const interval = setInterval(() => {
      fetchData()
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Dashboard
        </Typography>
        <IconButton
          color="inherit"
          onClick={toggleMessengerDrawer(!messengerOpen)}
          disabled={messengerToken === null}
        >
          <Badge
            badgeContent={unreadMessage}
            color="secondary"
            invisible={messengerToken === null || unreadMessage === 0}
          >
            <ForumIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit" onClick={handleClickOpenDialog}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogTitle>{t('common.areYouSureToLogout')}</DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            color="primary"
            autoFocus
          >
            {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Messenger
        messengerOpen={messengerOpen}
        toggleMessengerDrawer={toggleMessengerDrawer}
      />
    </AppBar>
  )
}

export default Header
