export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bajtów', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function odmiana(
  liczba: number,
  pojedyncza: string,
  mnoga: string,
  mnoga_dopelniacz: string
): string {
  liczba = Math.abs(liczba) // tylko jeśli mogą zdarzyć się liczby ujemne
  if (liczba === 1) return pojedyncza
  var reszta10 = liczba % 10
  var reszta100 = liczba % 100
  if (reszta10 > 4 || reszta10 < 2 || (reszta100 < 15 && reszta100 > 11))
    return mnoga_dopelniacz
  return mnoga
}
