import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import './App.css'
import './locales/i18n'

import Login from './components/Login/Login'
import Home from './components/Home/Home'

import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from './components/shared/Header'
import Navigation from './components/shared/Navigation'
import { makeStyles } from '@material-ui/core'
import { checkAuth } from './helpers/checkAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}))

const App = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth
  )

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage())
    })
  }, [dispatch])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {currentUser && (
        <>
          <Header open={open} handleDrawerOpen={handleDrawerOpen} messengerToken={currentUser.messengerToken} />
          <Navigation open={open} handleDrawerClose={handleDrawerClose} />
        </>
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path={['/', '/home']} component={Home} />
            <Redirect to="/login" />
          </Switch>
        </Router>
      </main>
    </div>
  )
}

export default App
