import jwtDecode from 'jwt-decode'
import { LOGOUT } from '../actions/types'
import store from '../store'
import AuthService from '../services/auth.service'

export const checkAuth = (authToken: string) => {
  if (authToken) {
    const decodedToken: any = jwtDecode(authToken)
    if (decodedToken.exp * 1000 < Date.now()) {
      AuthService.logout()
      
      store.dispatch({
        type: LOGOUT,
        payload: null
      })
    }
  }
}
