import React, { FunctionComponent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import MessengerService from '../../../services/messenger.service'
import { Channel } from '../../../store/Messenger/types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  defaultChannel: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))

type MessengerChannelsProps = {
  onChannelChange: (channelId: number) => void
  isActive: boolean
}

const MessengerChannels: FunctionComponent<MessengerChannelsProps> = ({
  onChannelChange,
  isActive
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(true)
  const [channel, setChannel] = useState<Channel | null>(null)
  const [channelList, setChannelList] = useState<Channel[]>([])

  const handleChannelChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setChannel(
      channelList.find((channel) => channel.channelId === event.target.value as number) || null
    )
  }
  
  useEffect(() => {
    const setDefaultChannel = async (channelId: number) => {
      try {
        await MessengerService.setDefaultChannel(channelId)
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      }
    }
    if (channel) {
      onChannelChange(channel.channelId)
      setDefaultChannel(channel.channelId)
    }
  }, [channel]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MessengerService.getChannelList()
        setChannelList(response.data.channelList)

        const defaultChannel = response.data.channelList.find(ch => ch.isDefault)
        if (defaultChannel) {
          setChannel(defaultChannel)
        }
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  return (
    <div
      style={{
        backgroundColor: '#e6e6e6',
        padding: '5px 2px 5px 10px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <Avatar className={classes.defaultChannel}>
            {channel?.channelName.charAt(0).toUpperCase()}
          </Avatar>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            className={classes.formControl}
            disabled={!isActive}
          >
            <InputLabel id="channel-select-label">{t('messenger.channel')}</InputLabel>
            <Select
              labelId="channel-select-label"
              id="channel-select"
              value={channel ? channel.channelId : ''}
              onChange={handleChannelChange}
              label={t('messenger.channel')}
            >
              {channelList.map((ch) => (
                <MenuItem value={ch.channelId} key={ch.channelId} selected={ch.isDefault}>
                  {ch.channelName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  )
}

export default MessengerChannels
