import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { getBase64 } from '../../../helpers/utils'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import MessengerService from '../../../services/messenger.service'
import {
  GroupConversationDetails,
  GroupParticipantUserId,
} from '../../../store/Messenger/types'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },
  groupImageName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '200px',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
  },
  groupImageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
}))

type MessengerEditGroupProps = {
  selectedUsers: number[]
  groupId: number
  onGroupUpdateCancel: () => void
  onGroupUpdateSuccess: () => void
  onSelectedUsers: (users: number[]) => void
}

const MessengerEditGroup: FunctionComponent<MessengerEditGroupProps> = ({
  selectedUsers,
  groupId,
  onGroupUpdateCancel,
  onGroupUpdateSuccess,
  onSelectedUsers,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(true)
  const [groupName, setGroupName] = useState<string>('')
  const [groupImage, setGroupImage] = useState<File | null>(null)
  const [groupImageBase64, setGroupImageBase64] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [groupDetails, setGroupDetails] =
    useState<GroupConversationDetails | null>(null)
  const [hasAvatar, setHasAvatar] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  useEffect(() => {
    const fetchGroupDetails = async () => {
      setLoading(true)
      try {
        const groupDetailsResponse =
          await MessengerService.getGroupConversationDetails(groupId)
        setGroupDetails(groupDetailsResponse.data)
        setGroupName(groupDetailsResponse.data.groupName)
        if (groupDetailsResponse.data.groupIconContent) {
          setHasAvatar(true)
          setGroupImageBase64(
            `data:${groupDetailsResponse.data.groupIconMimeType};base64,${groupDetailsResponse.data.groupIconContent}`
          )
        }
        const groupUsers = groupDetailsResponse.data.groupParticipants.map(
          (participant) => participant.id
        )
        onSelectedUsers(groupUsers)
      } catch (error) {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }

    fetchGroupDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId])

  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGroupName(event.target.value)
  }

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      setHasAvatar(true)
      setGroupImage(event.target.files[0] as File)
      const data = await getBase64(event.target.files[0])

      if (typeof data === 'string') {
        setGroupImageBase64(data)
      }
      event.target.value = ''
    }
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const editGroup = async (isActive: boolean = true) => {
    setIsSaving(true)
    setErrors([])
    const users: GroupParticipantUserId[] = []
    let iconFilename: string | null = null
    let iconContent: string | null = null
    if (groupDetails && hasAvatar) {
      iconFilename = groupImage ? groupImage.name : groupDetails.groupIconName
      iconContent = groupImage
        ? groupImageBase64.split(',')[1]
        : groupDetails.groupIconContent
    }
    const formErrors: string[] = []

    if (groupName === '') {
      formErrors.push(t('messenger.errors.groupNameRequired') as string)
    }
    if (selectedUsers.length === 0) {
      formErrors.push(t('messenger.errors.groupUsersRequired') as string)
    }

    if (formErrors.length > 0) {
      setErrors(formErrors)
      setIsSaving(false)
      return
    }

    selectedUsers.forEach((user) =>
      users.push({
        userId: user,
      })
    )

    try {
      await MessengerService.editGroupConversation(
        groupId,
        isActive,
        groupName,
        iconFilename,
        iconContent,
        users
      )
    } catch (error) {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString()

      console.warn(_content)
      setErrors([_content.detail])
    } finally {
      setIsSaving(false)
      onGroupUpdateSuccess()
    }
  }

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <div
            style={{
              height: '105px',
              backgroundColor: '#e6e6e6',
              padding: '10px 20px',
              color: '#595f6f',
            }}
          >
            <Typography variant="h6">
              {t('messenger.editGroup.title')}
            </Typography>
          </div>
          <div style={{ padding: '30px', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', height: 'calc(100% - 105px)' }}>
            <div>
              {errors.length > 0 && (
                <Alert severity="error" style={{ marginBottom: '25px' }}>
                  {errors.map((error) => (
                    <>
                      {error}
                      <br />
                    </>
                  ))}
                </Alert>
              )}
              <TextField
                id="group-name"
                label={t('messenger.editGroup.groupName')}
                variant="outlined"
                value={groupName}
                size="small"
                fullWidth
                onChange={handleGroupNameChange}
              />
              <Typography variant="subtitle2" style={{ margin: '35px 0 15px 0' }}>
                {t('messenger.editGroup.groupIcon')}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '30px',
                }}
              >
                <Avatar
                  style={{ width: '70px', height: '70px' }}
                  src={groupImageBase64}
                />
                <div style={{ marginLeft: '20px' }}>
                  <Typography variant="caption" gutterBottom display="block">
                    {t('messenger.editGroup.uploadIcon')}
                  </Typography>
                  {(groupImage || groupImageBase64 !== '') && (
                    <div className={classes.groupImageContainer}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setGroupImage(null)
                          setGroupImageBase64('')
                          setHasAvatar(false)
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>{' '}
                      <Typography
                        className={classes.groupImageName}
                        variant="caption"
                      >
                        {groupImage
                          ? groupImage.name
                          : groupDetails?.groupIconName}
                      </Typography>
                    </div>
                  )}
                  <input
                    className={classes.fileInput}
                    id="outlined-button-file"
                    type="file"
                    onChange={onFileChange}
                    accept="image/*"
                  />
                  <label htmlFor="outlined-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      size="small"
                      color="primary"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('messenger.editGroup.browse')}
                    </Button>
                  </label>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={onGroupUpdateCancel}
                >
                  {t('messenger.editGroup.cancel')}
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => editGroup()}
                  disabled={isSaving}
                >
                  {t('messenger.editGroup.save')}
                </Button>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={handleClickOpenDialog}
                disabled={isSaving}
                startIcon={<DeleteIcon />}
              >
                {t('messenger.editGroup.delete')}
              </Button>
            </div>
          </div>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogContent>
              <DialogTitle>{t('messenger.editGroup.areYouSureToDelete')}</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleCloseDialog}
                color="primary"
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => editGroup(false)}
                color="primary"
                disabled={isSaving}
                autoFocus
              >
                {t('common.yes')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

export default MessengerEditGroup
